function alert_warning(mensagem) {
    Swal.fire({
        position: 'center',
        type: 'warning',
        title: mensagem
    });
}

function alert_error(mensagem) {
    Swal.fire({
        position: 'center',
        type: 'error',
        title: mensagem
    });
}

function alert_success(mensagem, callback) {
    Swal.fire({
        position: 'center',
        type: 'success',
        title: mensagem
    }).then(callback);
}


function toast_success(title, message) {
    iziToast.success({
        title: title,
        message: message
    });
}

function toast_warning(title, message) {
    iziToast.warning({
        title: title,
        message: message
    });
}

function toast_error(title, message) {
    iziToast.error({
        title: title,
        message: message
    });
}

function toast_info(title, message) {
    iziToast.info({
        title: title,
        message: message
    });
}


function showSpinner(){
    $(".loading-io").show();
}
function hideSpinner(){
    $(".loading-io").hide();
}
