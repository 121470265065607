// Titulo
// ==========================================================================
(function () {
  try {
    if (!isInPage(".el"))
      return false;
  } catch (error) {
    console.log(error.name + ": " + error.message);
  }
}());

ENDERECO_SITE = '/';
INSTAGRAM_ACCESS_TOKEN = '7414015324.1677ed0.10886831c5da4a59a71a118b1ca9cb60';
INSTAGRAM_USER_ID = '7414015324';

// https://developer.mozilla.org/en-US/docs/Web/API/Node/contains
function isInPage(element) {
  var node = document.querySelector(element);
  return (node === document.body) ? false : document.body.contains(node);
}

jQuery(document).ready(function (e) {

  // Instagram
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#instafeed"))
        return false;

      var instagramFeed = new Instafeed({
        get: 'user',
        limit: 4,
        target: "instafeed",
        userId: INSTAGRAM_USER_ID,
        accessToken: INSTAGRAM_ACCESS_TOKEN,
        resolution: 'standard_resolution',
        template: '<li class="col col-xs-6 col-md-3">' +
          '<a href="{{image}}" title="{{caption}}" style="background-image: url({{image}})">' +
          '<img class="img-instagram -{{orientation}}" src="{{image}}" alt="{{caption}}">' +
          '</a>' +
          '</li>',
        after: function () {}
      });

      $('#instafeed').each(function () {
        instagramFeed.run();
      });

      $('#instafeed').magnificPopup({
        delegate: 'a',
        type: 'image',
        tLoading: 'Carregando #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: {
          enabled: true,
          navigateByImgClick: true,
          preload: [0, 1]
        },
        image: {
          tError: '<a href="%url%">A imagem #%curr%</a> não foi carregada.',
          titleSrc: function (item) {
            return item.el.attr('title');
          }
        }
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Compartilhar - página do produto
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#share"))
        return false;

      $("#share").jsSocials({
        showLabel: false,
        showCount: false,
        shares: ["email", "twitter", "facebook", "googleplus", "whatsapp"]
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());


  // Slider - Home
  // ==========================================================================
  (function () {
    try {
      if (!isInPage("#homeSlider"))
        return false;

      var homeSlider = $('#homeSlider').show().revolution({
        sliderType: "standard",
        sliderLayout: "fullwidth",
        minHeight: '400',
        delay: 9000,
        // extensions: "",
        navigation: {
          arrows: {
            style: "dione",
            enable: true,
            hide_onmobile: true,
            hide_under: 600,
            hide_onleave: true,
            hide_delay: 200,
            hide_delay_mobile: 1200,
            tmp: '<div class="tp-arr-imgwrapper"><div class="tp-arr-imgholder"></div></div>',
            left: {
              h_align: "left",
              v_align: "center",
              h_offset: 0,
              v_offset: 0
            },
            right: {
              h_align: "right",
              v_align: "center",
              h_offset: 0,
              v_offset: 0
            },
            bullets: {
              enable: false
            }
          }
        },
        gridwidth: [1200, 992, 460, 360, 320],
        gridheight: [400, 400, 460, 320]
      });

    } catch (error) {
      console.log(error.name + ": " + error.message);
    }
  }());

});


//LIMIT CHARACTERS
$(function () {
  $('[data-charlimit]').text(function () {
    var limit = $(this).data('charlimit');
    var elipsis = $(this).data('elipsis');
    var currentText = $(this).text();
    var limitedText = currentText.substr(0, limit);
    var lastIndex = limitedText.lastIndexOf(" ");
    if (!elipsis) elipsis = "";
    if (currentText > limitedText) {
      limitedText = limitedText.substr(0, lastIndex);
      return limitedText + elipsis;
    } else return currentText;
  });
});


// COLLAPSE FILTROS
(function () {
  var collapseFiltros = document.querySelector('#collapseFiltros');
  if (collapseFiltros) {
    if (mediaMaxWidth() < 768) {
      collapseFiltros.classList.remove('in');
    }
    window.addEventListener('resize', function () {
      if (mediaMaxWidth() < 768)
        collapseFiltros.classList.remove('in');
      else
        collapseFiltros.classList.add('in');
    });
  }
})();


// MEDIA QUERY MAX-WIDTH
function mediaMaxWidth() {
  var size = 9999,
    sizes = [1199, 991, 767, 480];
  sizes.forEach(function (element, index) {
    if (window.matchMedia('(max-width: ' + sizes[index] + 'px)').matches)
      size = sizes[index];
  });
  return size;
}

/**
 * Mostra menu lateral responsivo
 */
$(function () {
  $('nav#menuAside').mmenu({
    extensions: ['pageshadow', 'border-none', 'fx-menu-slide'],
    slidingSubmenus: false,
    counters: true,
    navbar: {
      title: 'Ricca Joias'
    },
    navbars: [{
      position: 'top',
    }],
  }, {
    transitionDuration: 150
  });
});


/**
 * Mostra menu fixo no topo
 */
$(function () {
  $(window).scroll(function () {
    if ($(this).scrollTop() >= 225) {
      $(".menuFixo-topo").addClass("showMenuFixo");

    } else {
      $(".menuFixo-topo").removeClass("showMenuFixo");
    }
  });
});